interface Props {
  /**
   * Set the size/radius of the spinner
   */
  size: string;
  /**
   * Customise the color of the spinner's stroke using hex value
   */
  color?: string;
  /**
   * Edit the width of the spinner's line
   */
  strokeWidth?: string;
  /**
   * Able to add class to customise its position or style
   */
  classes?: string;
}

/**
 * A reusable loading spinner component that can be used to indicate progress of retrieve data or
 * an indication of performing slow computations.
 *
 */
const LoadingSpinner = ({
  size,
  color = "#1f2937",
  strokeWidth = "2",
  classes,
}: Props) => {
  return (
    <svg
      stroke={color}
      width={size}
      height={size}
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth={strokeWidth}>
          <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  );
};

export default LoadingSpinner;
