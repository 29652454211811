"use client";

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { joinClassNames } from "@utils/helpers";
import { useInterval } from "@utils/hooks";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";

interface Rotator {
  data: {
    field1: string;
    field2: string;
    field3: string;
    link: string;
    target: string;
    image: string;
    blurb: string;
    booknow: boolean;
  };
  event: {
    evid: number;
    dateRange: string;
    startDate: string;
    endDate: string;
  };
}

interface Props {
  heroes: Rotator[];
  startingPage: number;
}

const variants = {
  show: {
    zIndex: 1,
    opacity: 1,
  },
  hide: {
    zIndex: 0,
    opacity: 0,
  },
};

function formatHttps(url: string) {
  if (url.startsWith("//")) {
    url = "https:" + url;
  }
  return url;
}

export default function Carousel({ heroes, startingPage }: Props) {
  const [page, setPage] = useState(startingPage);
  const [play, setPlay] = useState(true);
  const [paginateReady, setPaginateReady] = useState(true);

  useInterval(() => {
    if (play) {
      handlePaginate(1);
    }
  }, 5000);

  function handlePaginate(direction: number) {
    setPage((prev) => {
      if (prev === heroes.length - 1 && direction > 0) {
        return 0;
      }
      if (prev === 0 && direction < 0) {
        return heroes.length - 1;
      }
      return prev + direction;
    });
  }

  function paginate(direction: number) {
    if (paginateReady) {
      handlePaginate(direction);
      setPaginateReady(false);
      setTimeout(() => setPaginateReady(true), 500);
    }
  }

  return (
    <div onMouseEnter={() => setPlay(false)} onMouseLeave={() => setPlay(true)}>
      <div className="xl:hidden">
        <div className="w-full aspect-[2/1]" />
        <div className="w-full h-[100px]" />
      </div>
      <AnimatePresence>
        <motion.div
          key={`${heroes[page].data.field1}-hero`}
          variants={variants}
          initial="hide"
          animate="show"
          exit="hide"
          transition={{ duration: play ? 1 : 0.5 }}
          className="absolute inset-0"
        >
          <Link href={heroes[page].data.link}>
            <div className="max-xl:hidden absolute max-xl:bottom-0 max-xl:inset-x-0 xl:inset-y-0 xl:left-0 flex flex-col gap-2 xl:gap-6 xl:h-full xl:w-[calc(100%-810px)] bg-[radial-gradient(circle_at_bottom_right,_var(--tw-gradient-stops))] from-black to-gray-900 z-10 py-2 px-4 xl:py-4 xl:px-4">
              <h2 className="text-white text-xl xl:text-4xl font-black overflow-hidden text-ellipsis">
                {heroes[page].data.field1}
              </h2>
              <div className="flex flex-col xl:gap-2 xl:text-lg font-medium text-gray-500">
                <p>{heroes[page].data.field3}</p>
                <p>{heroes[page].event.dateRange}</p>
              </div>
            </div>
            <Image
              src={formatHttps(heroes[page].data.image.trim())}
              alt={heroes[page].data.field1}
              width={810}
              height={405}
              className="absolute max-xl:top-0 max-xl:inset-x-0 xl:inset-y-0 xl:right-0 object-cover max-xl:w-full xl:h-full aspect-[2/1] z-0"
              priority
              quality={100}
            />
          </Link>
        </motion.div>
      </AnimatePresence>
      <div className="xl:hidden absolute bottom-0 inset-x-0 h-[100px] p-1 flex flex-col items-center bg-gradient-to-t from-black/80">
        <h2 className="font-semibold text-white text-center line-clamp-1">
          {heroes[page].data.field1}
        </h2>
        <p className="mt-1 text-sm text-gray-300">{heroes[page].data.field3}</p>
        <p className="text-sm text-indigo-300">
          {heroes[page].event.dateRange}
        </p>
      </div>
      {heroes.length > 1 ? (
        <>
          <div
            className={joinClassNames(
              "absolute bottom-10 xl:inset-y-0 left-0 sm:left-4 z-10 flex items-center justify-center pointer-events-none max-xl:opacity-100 transition-opacity ease-in-out duration-200",
              !play ? "opacity-100" : "opacity-0"
            )}
          >
            <button
              aria-label="carousel-left"
              className="flex items-center justify-center h-10 w-10 rounded-lg xl:bg-gray-700/80 text-gray-50 xl:text-gray-400 pointer-events-auto hover:text-gray-50 transition-colors ease-in-out duration-200"
              onClick={() => paginate(-1)}
            >
              <ChevronLeftIcon className="h-8 w-8" />
            </button>
          </div>
          <div
            className={joinClassNames(
              "absolute bottom-10 xl:inset-y-0 right-0 sm:right-4 z-10 flex items-center justify-center pointer-events-none max-xl:opacity-100 transition-opacity ease-in-out duration-200",
              !play ? "opacity-100" : "opacity-0"
            )}
          >
            <button
              aria-label="carousel-right"
              className="flex items-center justify-center h-10 w-10 rounded-lg xl:bg-gray-700/80 text-gray-50 xl:text-gray-400 pointer-events-auto hover:text-gray-50 transition-colors ease-in-out duration-200"
              onClick={() => paginate(1)}
            >
              <ChevronRightIcon className="h-8 w-8" />
            </button>
          </div>
          <div className="absolute inset-x-4 bottom-2 xl:bottom-4 flex justify-center gap-1 z-20 pointer-events-none">
            {heroes.map((hero, index) => (
              <button
                aria-label={hero.data.field1}
                key={`${hero.data.field1}-${hero.event.evid ?? index}`}
                onClick={() => setPage(index)}
                className="group flex h-5 w-5 items-center justify-center pointer-events-auto"
              >
                <span
                  className={joinClassNames(
                    "w-2 h-2 rounded-full group-hover:bg-gray-200 transition-all ease-in-out duration-200",
                    page === index ? "bg-gray-200" : "bg-gray-500"
                  )}
                />
              </button>
            ))}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
