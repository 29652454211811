import { CheckoutDetails } from "@features/checkout/types";
import axios from "axios";
import { redirect } from "next/navigation";
import { cache } from "react";
import { getCookieValue } from "./helpers";
import { BASE_URL, COUNTRY_CODE } from "./constants";

export const fetcher = (url: string) =>
  axios
    .get(`${BASE_URL}/legacy/${COUNTRY_CODE}/shop/${url}`)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });

export async function basketFetcher(url: string) {
  const basketKey = getCookieValue("ASP.NET_SessionId");

  !basketKey &&
    (await fetch(`/api/basket`, {
      method: "POST",
    }));

  const newBasketKey = basketKey || getCookieValue("ASP.NET_SessionId");

  return axios
    .get(`${BASE_URL}/legacy/${COUNTRY_CODE}/shop/${url}`, {
      headers: {
        "basket-key": newBasketKey,
      },
    })
    .then((res) => {
      if (res.status !== 200) return null;
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

export const authorizedFetcher = ([url, token]: [string, string]) =>
  axios
    .get(`${BASE_URL}/legacy/${COUNTRY_CODE}/shop/${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });

export const getEdgeItem = async (key: string) =>
  await fetch(
    `https://edge-config.vercel.com/${process.env.EDGE_ID}/item/${key}?token=${process.env.EDGE_TOKEN}`
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
export const unPendOrder = (basketKey: string | undefined) =>
  axios.patch(
    `${BASE_URL}/legacy/${COUNTRY_CODE}/shop/basket/${basketKey}/checkout/unpend`
  );

export const basketSummaryFetcher = (url: string) =>
  axios
    .get(`${BASE_URL}/legacy/${COUNTRY_CODE}/shop/${url}`)
    .then(async (res) => {
      const { data } = res;

      if (!data) {
        await axios.delete("/api/basket");
        return null;
      }

      if (data.Ttl <= 0) {
        const basketKey = getCookieValue("ASP.NET_SessionId");
        const res = await fetch(
          `${BASE_URL}/legacy/${COUNTRY_CODE}/shop/basket/${basketKey}`,
          {
            method: "DELETE",
          }
        );
        if (res.status === 200) {
          await axios.delete("/api/basket");
        }
        return null;
      }

      return data;
    })
    .catch((err) => {
      throw err;
    });

export const getCheckoutDetail = cache(
  async (basketKey: string): Promise<CheckoutDetails | undefined> => {
    const detailRes = await fetch(
      `${BASE_URL}/legacy/${COUNTRY_CODE}/shop/basket/${basketKey}/checkout`
    );

    if (!detailRes.ok) {
      redirect("/cart");
    }

    return detailRes.json();
  }
);
